import React, { useEffect, useState } from 'react';

import classes from "./Filter.module.css";

import { useDispatch, useSelector } from 'react-redux';
import { setFilterOption, setRentType, setRoomsCount, setLocations, setDistricts, fetchRentTypes, setRentPeriodType, fetchRentPeriodTypes, setConveniences, fetchConveniences } from '../../store/actions/filterActions';
import Select from "./select/Select";
import {text} from "../../translate";
import {useTelegram} from "../../hooks/useTelegram";
import Range from "./range/Range";
import { applyFilters, applyRentTypeFilter, applyRoomsFilter, applyLocationFilter, applyPriceFilter } from '../../store/actions/catalogActions';
import { getRooms, getCitiesAndDistricts } from '../../store/thunks/filterThunks';

const Filter = () => {
  const dispatch = useDispatch();  
  const {lang} = useTelegram();
  const { rentType, roomsCount, cities, districts, priceRange, rentPeriodTypes, conveniences,
      selectedRentType, selectedRoomsCount, selectedCity, selectedDistrict, selectedRentPeriodType, selectedConveniences } = useSelector(state => state.filter);
  useEffect(() => {
    dispatch(fetchRentTypes([
      { id: 1, name: text[lang].filter.apartment },
      { id: 0, name: text[lang].filter.flat }
    ]));
    dispatch(fetchRentPeriodTypes(
      [
        //{id: 0, name: text[lang].filter.rent_daily},
        {id: 1, name: text[lang].filter.rent_monthly},
        {id: 2, name: text[lang].filter.rent_yearly},
      ]
    ));
    dispatch(getRooms());
    dispatch(getCitiesAndDistricts());
    dispatch(fetchConveniences([
      { id: 'is_near_subway', name: text[lang].flat.icons.is_near_subway },
      { id: 'is_balcony', name: text[lang].flat.icons.is_balcony },
      { id: 'is_gym', name: text[lang].flat.icons.is_gym },
      { id: 'is_pool', name: text[lang].flat.icons.is_pool },
      /*{ id: 'is_pets', name: text[lang].flat.icons.is_pets },
      { id: 'is_wifi', name: text[lang].flat.icons.is_wifi },
      { id: 'is_car_charge', name: text[lang].flat.icons.is_car_charge },
      { id: 'is_conditioner', name: text[lang].flat.icons.is_conditioner },
      { id: 'is_restaurant', name: text[lang].flat.icons.is_restaurant },
      { id: 'is_tv', name: text[lang].flat.icons.is_tv },
      { id: 'is_kitchenette', name: text[lang].flat.icons.is_kitchenette },
      { id: 'is_kitchen_utensils', name: text[lang].flat.icons.is_kitchen_utensils }, 
      { id: 'is_parking', name: text[lang].flat.icons.is_parking },
      { id: 'is_bathroom', name: text[lang].flat.icons.is_bathroom },
      { id: 'is_jacuzzi', name: text[lang].flat.icons.is_jacuzzi },
      { id: 'is_family_apartment', name: text[lang].flat.icons.is_family_apartment },
      { id: 'is_safe', name: text[lang].flat.icons.is_safe },
      { id: 'is_hair_dryer', name: text[lang].flat.icons.is_hair_dryer },
      { id: 'is_iron_for_clothes', name: text[lang].flat.icons.is_iron_for_clothes },
      { id: 'is_kettle', name: text[lang].flat.icons.is_kettle },
      { id: 'is_fridge', name: text[lang].flat.icons.is_fridge },
      { id: 'is_microwave', name: text[lang].flat.icons.is_microwave },
      { id: 'is_daily_cleaning', name: text[lang].flat.icons.is_daily_cleaning },
      { id: 'is_desktop', name: text[lang].flat.icons.is_desktop },*/
    ]
    ));
  }, [dispatch]);

  const handleRentTypeChange = (selectedRentType) => {
    dispatch(setRentType(selectedRentType));
  };

  const handleRoomsCountChange = (selectedRoomsCount) => {
    dispatch(setRoomsCount(selectedRoomsCount));
  };


  const handleCityChange = (selectedCity) => {
    dispatch(setLocations({ city: selectedCity[0], district: [] }));
    dispatch(setDistricts(selectedCity[0]?.locations || []));
    dispatch(setFilterOption(6, []));
  };

  const handleDistrictChange = (selectedDistrict) => {
    dispatch(setLocations({ city: selectedCity, district: selectedDistrict }));
  };

  const handleRentPeriodTypeChange = (selectedRentPeriodType) => {
    dispatch(setRentPeriodType(selectedRentPeriodType));
  };
  const handleConveniencesChange = (selectedConveniences) => {
    dispatch(setConveniences(selectedConveniences));
  }

  useEffect(() => {
    // Вызываем applyFilters только если хотя бы один параметр изменен
    dispatch(applyFilters(selectedRentType, selectedRoomsCount, { city: selectedCity, district: selectedDistrict }, priceRange, selectedRentPeriodType, selectedConveniences));
  }, [dispatch, selectedRentType, selectedRoomsCount, selectedCity, selectedDistrict, priceRange, selectedRentPeriodType, selectedConveniences]);


  return (
     <div className={classes._}>
          {/*<Select 
            id={1}
            name={text[lang].filter.rent} 
            options={rentType || []}  
            onOptionChange={handleRentTypeChange} 
            multi={true}
  />*/}
          <Select 
            id={3}
            name={text[lang].filter.rent} 
            options={roomsCount || []} 
            onOptionChange={handleRoomsCountChange} 
            multi={true}
          />
         {/*<Select
            id={2}
            name={text[lang].filter.rent_period}
            options={rentPeriodTypes}
            onOptionChange={handleRentPeriodTypeChange}
            multi={false}
          />*/}
         
      <Select
        id={4}
        name={text[lang].filter.city}
        options={cities}
        onOptionChange={handleCityChange}
        multi={false}
      />
      {districts?.length > 0 && (
        <Select
          id={6}
          name={text[lang].filter.district}
          options={districts}
          onOptionChange={handleDistrictChange}
          multi={true}
        />
      )}
         {/*<Select
            id={5} 
            name={text[lang].filter.conveniences} 
            options={conveniences || []} 
            onOptionChange={handleConveniencesChange} 
            multi={true}
         />*/}
         <Range/>
    </div>
  );
};

export default Filter;