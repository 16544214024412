import axios from 'axios';
import { api_addr } from '../../store/thunks/urls';
import { fetchLocations, fetchRoomsCount } from '../actions/filterActions';

export const getRooms = () => async (dispatch) => {
    try {
      const url = api_addr + `/rooms`;
      const roomsResponse = await axios.get(url); 
      var res = roomsResponse.data.map((item) =>({
        id: item.id,
        name: item.value,
        ru_id: item.value
      }));
      dispatch(fetchRoomsCount(res));
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
    }
  };
  export const getCitiesAndDistricts = () => async (dispatch) => {
    try {
      const url = api_addr + `/citys`;
      const response = await axios.get(url); 
      console.log(response.data);
      dispatch(fetchLocations(response.data));
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
    }
  };