import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Favorite from '../favorite/Favorite';
import ImageCard from '../imageCard/ImageCard';
import {saveEndIndex, saveScrollTop} from '../../store/actions/appActions';
import classes from "./Card.module.css";
import {useTelegram} from "../../hooks/useTelegram";
import {text} from "../../translate";
const Card = ({setSelectFlat, endIndex, appRef, id, photos, price_day_t, price_month_t, price_year_t, total_area, apartment_type, location, room, is_from_owner}) => {
    const dispatch = useDispatch();
    const {lang} = useTelegram();
    const rentPeriodTypeFilter = useSelector(state => state?.filter?.selectedRentPeriodType);
    const [rentPeriodTypePrice, setRentPeriodTypePrice] = useState(price_month_t);
    const [priceSubscription, setPriceSubscription] = useState(text[lang].card.price_month);
    var roomsNumber
    const type = [
        { id: 1, name: text[lang].flat.apartment },
        { id: 0, name: text[lang].flat.flat }
      ];
    const roomsCount = [
        {id:1, name: text[lang].rooms.room, ru_id: text['ru'].rooms.room},
        {id:2, name: text[lang].rooms.bunk, ru_id: text['ru'].rooms.bunk},
        {id:3, name: text[lang].rooms.studio, ru_id: text['ru'].rooms.studio},
        {id:4, name: text[lang].rooms.one_bedroom, ru_id: text['ru'].rooms.one_bedroom},
        {id:5, name: text[lang].rooms.two_bedroom, ru_id: text['ru'].rooms.two_bedroom},
        {id:6, name: text[lang].rooms.three_bedroom, ru_id: text['ru'].rooms.three_bedroom},
        {id:7, name: text[lang].rooms.village, ru_id: text['ru'].rooms.village},
    ]; 
    const saveScreenPosition = () => {
        dispatch(saveEndIndex(endIndex));
        dispatch(saveScrollTop(appRef?.current?.scrollTop));
    };  

    useEffect(() => {
        if(rentPeriodTypeFilter){
            switch (rentPeriodTypeFilter ? rentPeriodTypeFilter[0]?.id : null){
                case 0:
                    setRentPeriodTypePrice(price_day_t);
                    setPriceSubscription(text[lang].card.price_day);
                    break;
                case 1:
                    setRentPeriodTypePrice(price_month_t);
                    setPriceSubscription(text[lang].card.price_month);
                    break;
                case 2:
                    setRentPeriodTypePrice(price_year_t);
                    setPriceSubscription(text[lang].card.price_year);
                    break;
                default: 
                    break;
            }
        }
      }, [dispatch, rentPeriodTypeFilter]);
    return (
        <div className={`${classes._} ${is_from_owner ? classes.owner : ''}`}  onClick={()=>{
            saveScreenPosition();
            dispatch(setSelectFlat(id));
            }}>
            <ImageCard photos={photos} />
            <Favorite id={id} />
            <div className={classes.info}>
                <div className={classes.block}>
                    <p className={classes.price}>{rentPeriodTypePrice} {priceSubscription}</p>
                </div>
                <div className={classes.block_low}>
                    <p className={classes.loc}>{typeof location === 'object' && location !== null && 'name' in location ? location.name : null}                    </p>
                    <div className={classes.roomInfo}>
                        {room ? (
                            <p className={classes.type}>{roomsCount.find(i => i.ru_id === room?.value)?.name}</p>
                        ) : null}
                        <p className={classes.id}>id: {id}</p>
                    </div>
                    {/*<p className={classes.type}>{text[lang].flat.countRooms}: {roomsCount.find(i => i.id === room?.value)?.name}</p>*/}
                </div>
            </div>
        </div>
    );
};

export default Card;