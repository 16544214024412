import { SET_RENT_TYPE, 
  SET_ROOMS_COUNT, 
  SET_LOCATION, 
  SET_PRICE_RANGE,
  SET_RENT_PERIOD_TYPE,
  FETCH_LOCATIONS,
  FETCH_RENT_TYPES,
  FETCH_RENT_PERIOD_TYPES,
  FETCH_ROOMS_COUNT,
  FETCH_CONVENIENCES,
  SET_CONVENIENCES,
  SET_FILTER_OPTION,
  SET_DISTRICTS
   } from '../actions/filterActions';

const initialState = {
  loading: false,
  error: null,
  rentType: null,
  roomsCount: null,
  rentPeriodTypes: null,
  locations: null,
  cities: [],
  districts: [],
  priceRange: {from: null, to: null},
  selectedRentType: null,
  selectedRoomsCount: null,
  selectedCity: null,
  selectedDistrict: null,
  selectedRentPeriodType: [{id: 1}],
  conveniences: [],
  selectedConveniences: [],
  selectedOptions: {},
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTER_OPTION:
      const { filterId, options } = action.payload;
      return {
          ...state,
          selectedOptions: {
              ...state.selectedOptions,
              [filterId]: options
          }
      };
    case SET_RENT_TYPE:
      return { 
        ...state,
        selectedRentType: action.payload
    };
    case SET_ROOMS_COUNT:
      return { 
        ...state,
        selectedRoomsCount: action.payload
    };
    case SET_LOCATION:
      return { 
        ...state,
        selectedCity: action.payload.city,
        selectedDistrict: action.payload.district
      };
    case SET_PRICE_RANGE:
      return { 
        ...state,
        priceRange: action.payload
    };
    case SET_RENT_PERIOD_TYPE:
      return {
        ...state,
        selectedRentPeriodType: action.payload
    };
    case FETCH_RENT_PERIOD_TYPES:
      return {
        ...state,
        rentPeriodTypes: action.payload
    };
    case FETCH_LOCATIONS:
      return {
        ...state,
        cities: action.payload 
      };
    case SET_DISTRICTS:
      return {
        ...state,
        districts: action.payload,
      }
    case FETCH_RENT_TYPES:
      return {
        ...state,
        rentType: action.payload
      };
    case FETCH_ROOMS_COUNT:
      return {
        ...state,
        roomsCount: action.payload
      }
    case FETCH_CONVENIENCES:
      return {
        ...state,
        conveniences: action.payload
      }
      case SET_CONVENIENCES:
        return {
          ...state,
          selectedConveniences: action.payload
        }
    default:
      return state;
  }
};

export default filterReducer;